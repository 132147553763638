<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="Categories" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <div class="ion-padding">
          <ion-segment scrollable :value="category">
            <ion-segment-button value="gigs" @click="category = 'gigs'">
              <ion-label>Gigs</ion-label>
            </ion-segment-button>
            <ion-segment-button value="for-sale" @click="category = 'for-sale'">
              <ion-label>For Sale</ion-label>
            </ion-segment-button> 
          </ion-segment>
        </div>
        <div v-if="category == 'gigs'">
          <ion-radio-group :value="subCategory">
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Creative</ion-label>
              <ion-radio slot="start" color="success" value="creative" @click="subCategory = 'creative'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Audio Engineer</ion-label>
              <ion-radio slot="start" color="success" value="audio-engineer" @click="subCategory = 'audio-engineer'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Event</ion-label>
              <ion-radio slot="start" color="success" value="event" @click="subCategory = 'event'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Talent</ion-label>
              <ion-radio slot="start" color="success" value="talent" @click="subCategory = 'talent'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>General</ion-label>
              <ion-radio slot="start" color="success" value="general" @click="subCategory = 'general'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Writing</ion-label>
              <ion-radio slot="start" color="success" value="writing" @click="subCategory = 'writing'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Ride Sharing</ion-label>
              <ion-radio slot="start" color="success" value="ride-sharing" @click="subCategory = 'ride-sharing'"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
        <div v-if="category == 'for-sale'">
          <ion-radio-group :value="subCategory">
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Art</ion-label>
              <ion-radio slot="start" color="success" value="art" @click="subCategory = 'art'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Books</ion-label>
              <ion-radio slot="start" color="success" value="books" @click="subCategory = 'books'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>CDs/DVD/VHS</ion-label>
              <ion-radio slot="start" color="success" value="media" @click="subCategory = 'media'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Clothes</ion-label>
              <ion-radio slot="start" color="success" value="clothes" @click="subCategory = 'clothes'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Electronics</ion-label>
              <ion-radio slot="start" color="success" value="electronics" @click="subCategory = 'electronics'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Computers</ion-label>
              <ion-radio slot="start" color="success" value="computers" @click="subCategory = 'computers'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>General</ion-label>
              <ion-radio slot="start" color="success" value="general" @click="subCategory = 'general'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Instruments</ion-label>
              <ion-radio slot="start" color="success" value="instruments" @click="subCategory = 'instruments'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Tickets</ion-label>
              <ion-radio slot="start" color="success" value="tickets" @click="subCategory = 'tickets'"></ion-radio>
            </ion-item>
            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Video Gaming</ion-label>
              <ion-radio slot="start" color="success" value="video-gaming" @click="subCategory = 'video-gaming'"></ion-radio>
            </ion-item>
          </ion-radio-group>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonLabel, IonSegment, IonSegmentButton, IonRadioGroup, IonRadio, IonItem } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
//import apiClient from '../../services/api';
//import { squareOutline, checkbox } from 'ionicons/icons';

export default defineComponent({
  name: 'AddGenres',
  components: {
    IonContent, IonPage, ModalHeader, IonLabel, IonSegment, IonSegmentButton, IonRadioGroup, IonRadio, IonItem
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const category = ref('gigs');
    const subCategory = ref(null);
    const feedTrigger = computed(() => store.state.feedTrigger);

    onMounted(() => {

      storeCategory()
    })

    function storeCategory() {
      store.commit('setCategory', category.value)
    }

    function storeSubCategory() {
      store.commit('setSubCategory', subCategory.value)
    }

    return {
      category, subCategory, router, storeCategory, storeSubCategory, feedTrigger
    }
  },
  watch: {
    category: function() {
      this.storeCategory()
    },
    subCategory: function() {
      this.storeSubCategory()
      this.router.push({name:'CreateClassified'})
    }
  }
});
</script>

<style scoped>

</style>
